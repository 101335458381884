import { PageHeader, Spinner } from "components/ui";

import FirstTimeLoginTable from "./FirstTimeLoginTable";
import Header from "./Header";

import PatientSearchFilters from "./PatientSearchFilters";

import "./styles.css";

import React, { useEffect, useState } from "react";
import { useFetch } from "hooks";
import { get } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";
import "hooks/useQuery/local-query/style.scss";
import { useParams } from "react-router-dom";
import { MatchContext } from './MatchContext';
import PatientProfile from "components/screens/patient/patient-profile";
import { browserHistory } from "browser-history";

const FirstRegisterPage = () => {

  const { ticketNumberId } = useParams();

  const [firstTimePatientData, setFirstTimePatientData] = useState(null);
  const [ticketIdError, setTicketIdError] = useState(false);
  const [userHasSelectedPatient, setUserHasSelectedPatient] = useState(false);
  const [searchFilters, setSearchFilters] = useState({
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    firstName: "",
    lastName: "",
    ticketNumberId: ticketNumberId
  });
  const [selectedPatient, setSelectedPatient] = useState("")
  const [showProfile, setShowProfile] = useState(false)

  const { fetchData: getPatientByTicketID, isFetching } = useFetch({
    apiFn: () => get(`first-time-registration-dashboard/${ticketNumberId}`),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: err => errorMessage(`Failed to get First time Patient: ${err}`)
  });

  const getUserData = async () => {
      const res = await getPatientByTicketID();

      if (res) {
        setFirstTimePatientData(res);
      } else {
        setTicketIdError(true)
      }
  };

  useEffect(() => {
    
    getUserData();
  }, []);

  useEffect(() => {
    if (!firstTimePatientData) return;

    setSearchFilters(prev => {
      return {
        ...prev,
        email: firstTimePatientData?.Email,
        phoneNumber: firstTimePatientData?.PhoneNumber
      };
    });
  }, [firstTimePatientData]);

  if(ticketIdError){
    return <h1 style={{padding: "1em"}} > Error: ticket number is invalid </h1>
  }

  function selectPatient(patientId){
    setSelectedPatient(patientId)
    setShowProfile(true)
    setUserHasSelectedPatient(true)
  }

  function goBackFromPatientSelection(){
    setSelectedPatient("")
    setShowProfile(false)
    setUserHasSelectedPatient(false)
  }

  return (
    <MatchContext.Provider
        value={{
            selectPatient
        }}
    >
        <div className="phoneNumber-dashboard-container">
        <PageHeader title="First Register Matches">
            <button
                style={{ margin: 0, padding: 5 }}
                onClick={browserHistory.goBack}
                className="secondary button"
            >
                Go Back
            </button>
        </PageHeader>
        {isFetching ? (
            <Spinner />
        ) : (
            <>
            <Header
                patientData={firstTimePatientData}
                userHasSelectedPatient={userHasSelectedPatient}
                ticketNumberId={ticketNumberId}
                isFetchingUserData={isFetching}
                selectedPatient={selectedPatient}
                getUserData={getUserData}
            />
                {
                    showProfile || firstTimePatientData?.MatchedPatientId ?
                    <div>
                        {
                            firstTimePatientData?.MatchedPatientId ?
                            <>
                                <PatientProfile patientId={firstTimePatientData.MatchedPatientId} />
                            </> 
                            :
                            <>
                                <div className="goback-button-container">
                                    <button className="goback-button" onClick={goBackFromPatientSelection}>Go Back</button> 
                                </div>
                                <PatientProfile patientId={selectedPatient} />
                            </>
                        }
                    </div>
                    :
                    <>
                        <PatientSearchFilters
                            email={firstTimePatientData?.Email}
                            phoneNumber={firstTimePatientData?.PhoneNumber}
                            ticketNumberId={ticketNumberId}
                            setSearchFilters={setSearchFilters}
                        />
                        <FirstTimeLoginTable
                            searchFilters={searchFilters}
                            ticketNumberId={ticketNumberId}
                        />
                    </>
                }
            </>
        )}
        </div>
    </MatchContext.Provider>
  );
};

export default FirstRegisterPage;