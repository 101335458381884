import React from "react";
import PropTypes from "prop-types";
import StatsListItem from "./stats_list_item";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import { Pie, PieChart, Tooltip, ResponsiveContainer } from "recharts";

const OrderStatistics = ({ insights, onExport }) => (
  <div className="graph-card">
    <div className="card-top">
      {onExport && (<div className="stat-export-icon" data-tip="Export" onClick={onExport}><FaFileExcelO /></div>)}
      <h3 className="card-header">Order Stats</h3>
      <ul>
        <StatsListItem title="Submitted" value={insights.get("submitted")} />
        <StatsListItem title="Sleep Coach" value={insights.get("sleepcoach_count")} />
        <StatsListItem title="IVR" value={insights.get("ivr_count")} />
        <StatsListItem title="New" value={insights.get("new")} />
        <StatsListItem
          title="Coach Follow Up"
          value={insights.get("coach_followup")}
        />
        <StatsListItem
          title="Pending Approval"
          value={insights.get("pending")}
        />
        <StatsListItem title="Accepted" value={insights.get("accepted")} />
        <StatsListItem
          title="Location Assistance Needed"
          value={insights.get("location_assistance_needed")}
        />
        <StatsListItem
          title="Integration Follow up"
          value={insights.get("integration_follow_up")}
        />
        <StatsListItem
          title="Sent to Fulfillment"
          value={insights.get("pending_fulfillment")}
        />
        <StatsListItem
          title="Internal Processing"
          value={insights.get("internal_processing")}
        />
        <StatsListItem
          title="Pending Shipment"
          value={insights.get("pending_shipment")}
        />
        <StatsListItem
          title="Pending Buildout"
          value={insights.get("pending_buildout")}
        />
        <StatsListItem title="On Hold" value={insights.get("on_hold")} />
        <StatsListItem title="Completed" value={insights.get("completed")} />
        <StatsListItem
          title="Backordered"
          value={insights.get("backordered")}
        />
        <StatsListItem title="Rejected" value={insights.get("rejected")} />
        <StatsListItem title="Incorrect" value={insights.get("incorrect")} />
        <StatsListItem title="Ready For Review" value={insights.get("ready_for_review")} />
        <StatsListItem
          title="IVR Pending Work"
          value={insights.get("ivr_pending_work")}
        />
        <StatsListItem
          title="Voided"
          value={insights.get("voided")}
        />

      </ul>
    </div>

    <div className="card-bottom">
      <ResponsiveContainer height={200}>
        <PieChart>
          <Pie
            data={[
              {
                value: insights.get("new"),
                label: "New",
                fill: "#6BF4F4"
              },
              {
                value: insights.get("coach_followup"),
                label: "Coach Follow Up",
                fill: "#242133"
              },
              {
                value: insights.get("pending"),
                label: "Pending",
                fill: "#4EB5B5"
              },
              {
                value: insights.get("accepted"),
                label: "Accepted",
                fill: "#C2BBDE"
              },
              {
                value: insights.get("pending_fulfillment"),
                label: "Sent to Fulfillment",
                fill: "#469F9F"
              },
              {
                value: insights.get("internal_processing"),
                label: "Internal Processing",
                fill: "#3dae93"
              },
              {
                value: insights.get("pending_shipment"),
                label: "Pending Shipment",
                fill: "#409090"
              },
              {
                value: insights.get("backordered"),
                label: "Back Ordered",
                fill: "#F38630"
              },
              {
                value: insights.get("completed"),
                label: "Completed",
                fill: "#E9F609"
              },
              {
                value: insights.get("rejected"),
                label: "Rejected",
                fill: "#CC4530"
              }
            ]}
            dataKey="value"
            nameKey="label"
            isAnimationActive={false}
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
);

OrderStatistics.defaultProps = {
  insights: {}
};

OrderStatistics.propTypes = {
  insights: PropTypes.object,
  onExport: PropTypes.func
};

export default OrderStatistics;
