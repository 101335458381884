import React from 'react'
import StatsListItem from "./stats_list_item";
import PropTypes from "prop-types";
import { formatPercantage } from "utils/misc";

const ContactStatslist = ({ insights }) => {
  return (
    <>
      <ul>
        <StatsListItem
          title="Total"
          value={insights.get("exposed_contacts_success_total")}
        />
        <StatsListItem
          title="Outgoing Calls"
          value={insights.getIn(["phone", "outgoing_call", "successes"])}
        />
        <StatsListItem
          title="IVR Outgoing Calls"
          value={insights.getIn(["phone", "outgoing_call_ivr", "successes"])}
          style={{ paddingLeft: 15 }}
        />
        <StatsListItem
          title="Sleep Coach Outgoing Calls"
          value={insights.getIn(["phone", "outgoing_call_sleep_coach", "successes"])}
          style={{ paddingLeft: 15 }}
        />
        <StatsListItem
          title="   Voicemails"
          value={`${insights.getIn([
            "phone",
            "left_voicemail",
            "successes",
          ])} / ${formatPercantage(
            insights.getIn(["phone", "left_voicemail", "successes"]),
            insights.getIn(["phone", "outgoing_call", "successes"])
          )}`}
          style={{ paddingLeft: 15 }}
        />
        <StatsListItem
          title="Incoming Calls"
          value={insights.getIn(["phone", "incoming_call", "successes"])}
        />
        <StatsListItem
          title="IVR Incoming Calls"
          value={insights.getIn(["phone", "incoming_call_ivr", "successes"])}
          style={{ paddingLeft: 15 }}
        />
        <StatsListItem
          title="Sleep Coach Incoming Calls"
          value={insights.getIn(["phone", "incoming_call_sleep_coach", "successes"])}
          style={{ paddingLeft: 15 }}
        />
        <StatsListItem
          title="Emails"
          value={insights.getIn(["email", "success_total"])}
        />
        <StatsListItem
          title="Text Messages"
          value={insights.getIn(["sms", "text_message", "successes"])}
        />
        <StatsListItem
          title="Push Notifications"
          value={insights.getIn(["sms", "push_notification", "successes"])}
        />
      </ul>
    </>
  );
};

ContactStatslist.propTypes = {
  insights: PropTypes.object,
};

export default ContactStatslist