import React from "react";
import { Link } from "react-router-dom";
import { RowSelectCell, RowSelectHeaderCell } from "hooks/useRowSelectNew";
import { useTableSettingsNew } from "hooks/useTableSettings";
import { getFormOptionsSleepCoachWithUserFirst, selectors } from "reducers";
import { useSelector } from "react-redux";
import { formatDate } from "utils/dates";
import MdPriorityHigh from "react-icons/lib/md/priority-high";
import CompanyTeamSelect from "components/shared/company-team-select";
import SleepCoachTeamSelect from "components/shared/coach-team-select";
import { platformOptions, yesNoOptions } from "utils/enum";
import PendingInfo from "components/order-status/pending-info";
import DateRangeHeaderColumn from "hooks/useQuery/api-query/table-header/date-range-header-filter";
import CompanyBranchFilter from "./company-branch-filter";
import * as R from "ramda";
import ClaimOrder from "./claim-order";

const patientSubmitted = [{ GUID: "PATIENT", active: true, name: "PATIENT", text: "PATIENT", value: "PATIENT"}];
const getTableSchema = ({
  orderStatus,
  companies,
  admins,
  sleepCoaches,
  userRole,
  orderStatusOptions,
  insuranceCompanies 
}) => {
  const Table = {
    All: {
      header: "All",
      field: "all",
      Cell: RowSelectHeaderCell,
      active: true,
      Row: row => <RowSelectCell id={row.id} />
    },
    Admin: {
      header: "Admin",
      active: true,
      Row: row => (
        <td>
          <ClaimOrder claimed_by={row.claimed_by} id={row.id} />
        </td>
      ),
      dropdownPosition: "right",
      filters: admins,
      field: "claimed_by",
      filterKey: "admin",
      filterDownSelect: true
    },
    "Order Number": {
      header: "Order Number",
      active: true,
      field: "invoice_number",
      Row: row => (
        <td>
          <Link to={`/orders/${row.id}`}>{row.invoice_number}</Link>
        </td>
      )
    },
    Patient: {
      header: "Patient",
      active: true,
      field: "patient",
      Row: row => (
        <td>
          <Link to={`/patients/${row.patient_id}`}>{row.patient}</Link>
        </td>
      )
    },
    "Account #": {
      header: "Account #",
      field: "patient_account_number",
      active: true,
      Row: row => <td>{row.patient_account_number}</td>,
      disabled: orderStatus !== "on_hold" && orderStatus !== "incorrect"
    },
    Company: {
      header: "Company",
      field: "company",
      filters: companies,
      filterDownSelect: true,
      active: true,
      Row: row => <td>{row.company}</td>,
      disabled: !["SleepCoach", "Administrator"].includes(userRole),
      SubFilter: CompanyTeamSelect
    },
    "Company ": {
      header: "Company",
      field: "company",
      active: true,
      Row: row => <td>{row.company}</td>,
      disabled: ["SleepCoach", "Administrator"].includes(userRole),
      Cell: CompanyBranchFilter
    },
    Branch: {
      header: "Branch",
      field: "company_branch_name",
      active: true,
      Row: row => <td>{row.company_branch_name}</td>,
      disabled: ["SleepCoach", "Administrator"].includes(userRole)
    },
    Status: {
      header: "Status",
      field: "status",
      filters: orderStatusOptions,
      active: true,
      Row: row => <td>{row.status}</td>,
      disabled: orderStatus !== "delayed"
    },
    Submitted: {
      header: "Submitted",
      Cell: () => (
        <DateRangeHeaderColumn header="Submitted" field="submitted" />
      ),
      active: true,
      field: "submitted",
      Row: row => <td>{formatDate(row.submitted)}</td>
    },
    "Status changed": {
      header: "Status changed",
      active: true,
      field: "status_changed",
      Row: row => <td>{formatDate(row.status_changed)}</td>
    },
    "Order Progress": {
      header: "Order Progress",
      active: true,
      key: "Order Progress",
      field: "hold_count_total",
      filters: [
        {
          text: "Yes",
          value: "true"
        },
        {
          text: "No",
          value: "false"
        }
      ],
      Row: row => (
        <td>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {row.hold_count_closed}/{row.hold_count_total}
            {row.hold_count_open > 1 && (
              <MdPriorityHigh size="16px" color="red" />
            )}
          </div>
        </td>
      )
    },
    "Last Updated": {
      header: "Last Updated",
      active: true,
      field: "last_updated",
      Row: row => <td>{formatDate(row.last_updated)}</td>
    },
    "Delayed Date": {
      header: "Delayed Date",
      active: true,
      field: "pending_delayed_date",
      filters: yesNoOptions,
      Row: row => <td>{formatDate(row.pending_delayed_date)}</td>
    },

    "Entered By": {
      header: "Entered By",
      field: "entered_by",
      filters: sleepCoaches.length > 0 ? sleepCoaches.concat(patientSubmitted) : [],
      filterDownSelect: true,
      active: true,
      Row: row => <td>{row.entered_by}</td>,
      SubFilter: SleepCoachTeamSelect,
      disabled: ![
        "SleepCoach",
        "Administrator",
        "ServiceAdmin",
        "ServiceCoach",
        'ContractorAdministrator'
      ].includes(userRole) || orderStatus == "pending"
    },
    Assignment: {
      header: "Assignment",
      active: true,
      field: "sleep_coach_assignment",
      Row: row => <td>{row.sleep_coach_assignment}</td>,
      filters: sleepCoaches,
      filterDownSelect: true,
      SubFilter: SleepCoachTeamSelect
    },
    Team: {
      header: "Team",
      active: true,
      field: "team_name",
      Row: row => <td>{row.team_name}</td>,
      disabled: orderStatus == "pending"
    },
    LastOrderDate: {
      header: "Last Order Date",
      Cell: () => (
        <DateRangeHeaderColumn header="Last Order Date" field="last_order_date" />
      ),
      active: true,
      field: "last_order_date",
      disabled: orderStatus != "pending",
      Row: row => <td>{formatDate(row.last_order_date)}</td>
    },
    Platform: {
      header: "Platform",
      active: true,
      field: "platform",
      filters: platformOptions,
      Row: row => <td>{row.platform}</td>
    },
    Insurance: {
      header: "Insurance",
      field: "insurance",
      active: true,
      filters: insuranceCompanies,
      filterDownSelect: true,
      Row: row => <td>{row.insurance}</td>
    },
    Priority: {
      header: "Priority",
      field: "high_priority",
      active: true,
      Row: row => (
        <td>
          {row.high_priority ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <MdPriorityHigh size="16px" color="red" />
            </div>
          ) : null}
        </td>
      ),
      filters: [
        {
          text: "Yes",
          value: "true"
        },
        {
          text: "No",
          value: "false"
        }
      ]
    },
    "On Hold Reason": {
      header: "On Hold Reason",
      field: "onHoldReason",
      active: true,
      disabled: orderStatus != "on_hold",
      style: { width: 200, minWidth: 200 },
      Row: row => <td>{row.onHoldReason}</td>
    },
    "Reason Rejected": {
      header: "Reason Rejected",
      field: "reason",
      active: true,
      Row: row => (
        <td>
          <div style={{ width: 200 }}>{row.reason}</div>
        </td>
      ),
      disabled: orderStatus !== "rejected" && orderStatus !== "voided"
    },
    "Pending Info": {
      header: "Pending Info",
      active: true,
      field: "pending_info",
      Row: row => (
        <td>
          <PendingInfo orderId={row.id} value={row.pending_info} />
        </td>
      ),
      disabled: orderStatus === "rejected" || orderStatus === "voided"
    }
  };
  return Table;
};

export const useOrdersTable = orderStatus => {
  let companies = useSelector(selectors.getFormOptionsCompany);
  companies.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    return 0;
  });
  const userRole = useSelector(selectors.getUserRole);
  const orderStatusOptions = useSelector(selectors.getOrderStatusTextOptions);
  const admins = useSelector(selectors.getEnumAdmins);
  const sleepCoaches = useSelector(getFormOptionsSleepCoachWithUserFirst);
  const insuranceCompanies  = useSelector(selectors.getInsuranceCompanyOptions);
  const orderStatusTable = getTableSchema({
    orderStatus,
    companies,
    sleepCoaches,
    admins,
    userRole,
    orderStatusOptions,
    insuranceCompanies
  });
  const tableSchema = Object.keys(orderStatusTable).map(key => ({
    ...orderStatusTable[key],
    key
  }));
  const defaultSettings = R.mapObjIndexed(R.pick(["active"]), orderStatusTable);
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings
  } = useTableSettingsNew("order-status-new-table-settings", defaultSettings);

  const visibleTableSchema = tableSchema.filter(
    ({ key, disabled }) => isColumnVisible(key) && !disabled
  );
  const tableColumns = visibleTableSchema.map(R.omit(["Row"]));
  const tableRows = visibleTableSchema.map(R.prop("Row"));

  return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    tableColumns,
    tableRows
  };
};
