import React from "react";
import PropTypes from "prop-types";
import StatsListItem from "./stats_list_item";
import { graphColors } from "utils/styles";
import { Pie, PieChart, Tooltip, ResponsiveContainer } from "recharts";

const OrdersCreated = ({ insights }) => (
  <div className="graph-card">
    <div className="card-top">
      <h3 className="card-header">Orders Submitted By</h3>
      <ul>
        <StatsListItem
          title="Administrator"
          value={insights.get("Administrator")}
        />
        <StatsListItem
          title="Company Administrator"
          value={insights.get("CompanyAdministrator")}
        />
        <StatsListItem
          title="Office Administrator"
          value={insights.get("OfficeAdministrator")}
        />
        <StatsListItem title="Technology" value={insights.get("OrderCountTech")} />
          <StatsListItem title="Email" value={ insights.get("OrderCountTechEmail") } style={{ paddingLeft: 15 }} />
          <StatsListItem title="Text" value={ insights.get("OrderCountTechText") } style={{ paddingLeft: 15 }} />
          <StatsListItem title="App" value={ insights.get("OrderCountTechApp") } style={{ paddingLeft: 15 }} />
        <StatsListItem title="SleepCoach" value={insights.get("SleepCoach")} />
        <StatsListItem title="IVR" value={insights.get("IVR")} />
        <StatsListItem title="Therapist" value={insights.get("Therapist")} />
        <StatsListItem title="Patient External Portal" value={insights.get("PatientExternalPortal")} />
        {insights.get("Unknown") > 0 && (
          <StatsListItem title="Unknown" value={insights.get("Unknown")} />
        )}
      </ul>
    </div>
    <div className="card-bottom">
      <ResponsiveContainer height={200}>
        <PieChart>
          <Pie
            data={[
              {
                value: insights.get("Administrator"),
                label: "Administrator",
                fill: graphColors[0]
              },
              {
                value: insights.get("CompanyAdministrator"),
                label: "CompanyAdministrator",
                fill: graphColors[1]
              },
              {
                value: insights.get("OfficeAdministrator"),
                label: "OfficeAdministrator",
                fill: graphColors[2]
              },
              {
                value: insights.get("SleepCoach"),
                label: "SleepCoach",
                fill: graphColors[3]
              },
              {
                value: insights.get("Therapist"),
                label: "SleepCoach",
                fill: graphColors[4]
              },
              {
                value: insights.get("Patient"),
                label: "Technology",
                fill: graphColors[5]
              },
              {
                value: insights.get("Unknown"),
                label: "Unknown",
                fill: graphColors[6]
              }
            ]}
            dataKey="value"
            nameKey="label"
            isAnimationActive={false}
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
);

OrdersCreated.defaultProps = {
  insights: {}
};

OrdersCreated.propTypes = {
  insights: PropTypes.object
};

export default OrdersCreated;
