import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { invalidateAdminInsights, fetchAdminInsights } from "actions/insights";
import { withDatePicker } from "components/ui/with-date-picker";
import DayPickerRange from "components/ui/day-picker-range";
import { today } from "utils/dates";
import {
  PatientStatistics,
  OrderStatistics,
  ContactStatistics,
  ItemStatistics,
  OrdersCreatedBy,
  CompletedOrders
} from "components/ui/statistics";
import { SumBlock, PageHeader, Waiting } from "components/ui";
import accounting from "accounting";
import moment from "moment";
import HasRolePermission from "components/shared/has-role-permission";

class Insights extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      dates: { to, from },
      userId,
      fetchAdminInsights,
      invalidateAdminInsights
    } = this.props;
    const start_date = moment(from).format("MM/DD/YYYY");
    const end_date = moment(to).format("MM/DD/YYYY");
    invalidateAdminInsights({ userId, start_date, end_date });
    fetchAdminInsights({ userId, start_date, end_date });
  };

  componentDidMount() {
    const {
      dates: { initStartDate, initEndDate },
      userId,
      fetchAdminInsights,
      invalidateAdminInsights
    } = this.props;
    invalidateAdminInsights({
      userId,
      start_date: initStartDate,
      end_date: initEndDate
    });
    fetchAdminInsights({
      userId,
      start_date: initStartDate,
      end_date: initEndDate
    });
  }

  render() {
    const button = { path: `/`, value: "Close Insights" };
    const {
      orderInsights,
      patientInsights,
      contactsInsights,
      isFetchingInsights,
      handleToClick,
      handleFromClick,
      dates: { initStartDate, initEndDate }
    } = this.props;
    return (
      <div className="insights-page-container">
        <PageHeader title="Insights" button={button} />
        <div className="date-select-row">
          <div>
            <DayPickerRange
              handleToClick={handleToClick}
              handleFromClick={handleFromClick}
              initStartDate={initStartDate}
              initEndDate={initEndDate}
              fromLabel="Start Date"
              toLabel="End Date"
            />
          </div>
          {isFetchingInsights ? (
            <button className="postfix" disabled>
              ...
            </button>
          ) : (
            <button
              type="submit"
              className="postfix"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          )}
        </div>

        {orderInsights && !isFetchingInsights ? (
          <React.Fragment>
            <ItemStatistics insights={orderInsights} />
            <div className="orders-submitted-cards">
              <div className="statistic-card">
                <PatientStatistics insights={patientInsights} />
                <SumBlock
                  title="Patients Due For Outreach"
                  value={
                    patientInsights.get("eligible_for_supplies")
                  }
                />
              </div>
              <div className="statistic-card">
                <OrderStatistics insights={orderInsights} />
                <HasRolePermission
                  allowedRoles={["Administrator", "CompanyAdministrator"]}
                  renderElse={
                    <SumBlock
                      title="Total Orders"
                      value={orderInsights.get("total")}
                    />
                  }
                >
                  <SumBlock
                    title="Estimated Revenue"
                    value={accounting.formatMoney(
                      orderInsights.get("estimated_revenue")
                    )}
                  />
                </HasRolePermission>
              </div>
              <div className="statistic-card">
                <ContactStatistics insights={contactsInsights} />
                <SumBlock
                  title="Total Contacts"
                  value={contactsInsights.getIn(["all_contacts_total"])}
                />
              </div>
              <div className="statistic-card">
                <OrdersCreatedBy
                  insights={orderInsights.get("entered_by_counts")}
                />
                <SumBlock
                  title="Total Orders Submitted"
                  value={orderInsights
                    .get("entered_by_counts")
                    .reduce((v, acc) => v + acc, 0)}
                />
              </div>
            </div>
            <CompletedOrders
              data={orderInsights.get("completed_order_stats")}
            />
          </React.Fragment>
        ) : (
          <Waiting reason="crunching numbers..." />
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    patientInsights: state.getIn(["insights", "patientInsights"]),
    orderInsights: state.getIn(["insights", "orderInsights"]),
    contactsInsights: state.getIn(["insights", "contactsInsights"]),
    isFetchingInsights: state.getIn(["fetch", "GET_INSIGHTS", "isFetching"]),
    userId: state.getIn(["user", "id"])
  }),
  {
    invalidateAdminInsights,
    fetchAdminInsights
  }
)(
  withDatePicker(Insights)({
    initStartDate: today(),
    initEndDate: today()
  })
);

Insights.propTypes = {
  getPatientInsights: PropTypes.func,
  getOrderInsights: PropTypes.func,
  getContactInsights: PropTypes.func,
  orderInsights: PropTypes.object,
  patientInsights: PropTypes.object,
  contactsInsights: PropTypes.object,
  isFetchingInsights: PropTypes.bool,
  handleToClick: PropTypes.func,
  handleFromClick: PropTypes.func,
  dates: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    initStartDate: PropTypes.string,
    initEndDate: PropTypes.string
  }),
  userId: PropTypes.string.isRequired,
  fetchAdminInsights: PropTypes.func.isRequired,
  invalidateAdminInsights: PropTypes.func.isRequired
};
